import { FoodCart, YelpCategory } from './types/types';
import { ReactComponent as Yelp } from './icons/yelp.svg';

import './search-bar.css';

export const SearchBar = ({
  carts,
  filteredCarts,
  setFilteredCarts,
}: {
  carts: FoodCart[];
  filteredCarts: FoodCart[];
  setFilteredCarts: React.Dispatch<React.SetStateAction<FoodCart[]>>;
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const existsInCategories = (categories: YelpCategory[], search: string) => {
      for (const { alias, title } of categories) {
        if (
          alias.toLowerCase().includes(search) ||
          title.toLowerCase().includes(search)
        )
          return true;
      }
      return false;
    };

    const search = e.target.value.toLowerCase();
    setFilteredCarts(
      carts.filter(
        (cart: FoodCart) =>
          cart.name.toLowerCase().includes(search) ||
          existsInCategories(cart.categories, search)
      )
    );
  };

  return (
    <div id="search-bar">
      <div className="search-bar-input-container">
        <input placeholder="What do you want to eat?" onChange={onChange} />
      </div>
      <div className="found-carts-and-yelp">
        <div className="found-carts">
          Found <strong>{filteredCarts.length}</strong> carts!
        </div>
        <Yelp />
      </div>
    </div>
  );
};
