import axios from 'axios';
import { useState, useEffect } from 'react';
import { FoodCart } from '../types/types';

export const useFetch = (url: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [carts, setCarts] = useState<FoodCart[]>([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCarts = async () => {
      try {
        const { data } = await axios(url);
        setCarts(data);
        setLoading(false);
      } catch (error: any) {
        setError(error);
        setLoading(false);
      }
    };

    fetchCarts();
  }, [url]);

  return { loading, carts, error };
};
