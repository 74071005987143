import { useFetch } from './hooks/fetch';
import { FoodCart } from './types/types';
import { useEffect, useRef, useState } from 'react';
import { FoodCartsMap } from './map';
import { Results } from './results';
import { SearchBar } from './search-bar';
import { Loading } from './loading';
import { Error } from './error';

import './App.css';

export const App = () => {
  const { loading, carts, error } = useFetch(
    'https://d3landkko4b20v.cloudfront.net/food-carts.json'
  );

  const [filteredCarts, setFilteredCarts] = useState<FoodCart[]>([]);
  const [selectedFoodCart, setSelectedFoodCart] = useState<FoodCart | null>(
    null
  );

  const cartsRef = useRef<Map<string, HTMLLIElement> | null>(null);
  const getRefsMap = () => {
    if (!cartsRef.current) {
      cartsRef.current = new Map();
    }
    return cartsRef.current;
  };

  useEffect(() => {
    setFilteredCarts(carts);
  }, [carts]);

  if (loading) {
    return <Loading />;
  } else if (error) {
    return <Error />;
  }

  return (
    <div className="App">
      <header id="header">
        <span>Portland Food Carts</span>
      </header>
      <div id="content">
        <div id="sidebar">
          <SearchBar
            carts={carts}
            filteredCarts={filteredCarts}
            setFilteredCarts={setFilteredCarts}
          />
          <Results
            carts={filteredCarts}
            setSelectedFoodCart={setSelectedFoodCart}
            getRefsMap={getRefsMap}
          />
        </div>
        <FoodCartsMap
          carts={filteredCarts}
          selectedFoodCart={selectedFoodCart}
          setSelectedFoodCart={setSelectedFoodCart}
          getRefsMap={getRefsMap}
        />
      </div>
    </div>
  );
};
