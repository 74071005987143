import { FoodCart } from './types/types';

const excludedCategories = [
  'food carts',
  'food court',
  'food trucks',
  'food stands',
  'street vendors',
  'caterers',
];

export const getCategories = (cart: FoodCart) => {
  return cart.categories
    .map(({ title }) => title)
    .filter((v) => !excludedCategories.includes(v.toLowerCase()))
    .join(', ');
};
