import { FoodCart } from './types/types';
import { Vendor } from './vendor';

import './results.css';

export const Results = ({
  carts,
  setSelectedFoodCart,
  getRefsMap,
}: {
  carts: FoodCart[];
  setSelectedFoodCart: React.Dispatch<React.SetStateAction<FoodCart | null>>;
  getRefsMap: () => Map<string, HTMLLIElement>;
}) => {
  return (
    <ul id="results">
      {carts.map((cart: FoodCart, index: number) => {
        return (
          <li
            className="result-cart"
            key={`cart-${index}`}
            onClick={() => setSelectedFoodCart(cart)}
            ref={(node) => {
              const map = getRefsMap();
              if (node) {
                map.set(cart.id, node);
              } else {
                map.delete(cart.id);
              }
            }}
          >
            <Vendor cart={cart} />
          </li>
        );
      })}
    </ul>
  );
};
