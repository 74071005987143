import { FoodCart } from './types/types';

import { ReactComponent as Star } from './icons/star.svg';

import './vendor-popup.css';
import { getCategories } from './helpers';

export const VendorPopup = ({ cart }: { cart: FoodCart }) => {
  const ratings = (rating: number) => {
    return (
      <>
        {[...Array(5)].map((_, index) => {
          return index < rating ? (
            <Star key={index} fill="#bc8034" height={'16px'} />
          ) : (
            <Star key={index} height={'16px'} />
          );
        })}
      </>
    );
  };

  const categories = getCategories(cart);
  return (
    <>
      <div className="vendor-popup-cart-name">{cart.name}</div>
      <div className="vendor-popup-metadata">
        <div className="label">Address</div>
        <div className="vendor-popup-text">{cart.address}</div>
      </div>

      {categories.length > 0 && (
        <div className="vendor-popup-metadata">
          <div className="label">Type</div>
          <div className="vendor-popup-text">{categories}</div>
        </div>
      )}

      <div className="vendor-popup-metadata">
        <div className="label">Rating</div>
        <div>{ratings(cart.rating)}</div>
      </div>

      <div className="vendor-popup-metadata">
        <div className="label">More Information</div>
        <a href={cart.url} target="_blank" rel="noreferrer">
          <span className="vendor-popup-text">Website</span>
        </a>
      </div>
    </>
  );
};
