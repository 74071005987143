import { useState, useCallback, useEffect, useRef } from 'react';
import Map, {
  GeolocateControl,
  Layer,
  LayerProps,
  MapLayerMouseEvent,
  MapRef,
  Popup,
  Source,
} from 'react-map-gl';
import { FoodCart } from './types/types';
import { VendorPopup } from './vendor-popup';

import mapboxgl from 'mapbox-gl';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = MapboxWorker;

export const FoodCartsMap = ({
  carts,
  selectedFoodCart,
  setSelectedFoodCart,
  getRefsMap,
}: {
  carts: FoodCart[];
  selectedFoodCart: FoodCart | null;
  setSelectedFoodCart: React.Dispatch<React.SetStateAction<FoodCart | null>>;
  getRefsMap: () => Map<string, HTMLLIElement>;
}) => {
  const accessToken =
    'pk.eyJ1Ijoic3Bpa2UtbWFkZGVuIiwiYSI6ImNqNnM0cnRrcDBqNDAzMnBid3dtem1wcXgifQ.2advMY_wpO8eu24eg6OeOQ';

  const mapRef = useRef<MapRef | null>(null);

  const [cursor, setCursor] = useState<string>('auto');
  const [showPopup, setShowPopup] = useState(false);

  const onMouseEnter = useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = useCallback(() => setCursor('auto'), []);

  const scrollToId = (id: string) => {
    const map = getRefsMap();
    const node = map.get(id);
    if (node) {
      node.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  const onClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const { features } = event;
      const clickedFeature = features && features[0];

      if (clickedFeature) {
        // 🤮 - not my fault though
        const cart = {
          ...clickedFeature.properties,
          categories: JSON.parse(clickedFeature.properties?.categories),
          coordinates: JSON.parse(clickedFeature.properties?.coordinates),
        } as FoodCart;

        setSelectedFoodCart(cart);
        setShowPopup(true);
        scrollToId(cart.id);
      }
    },

    [setSelectedFoodCart]
  );

  const geojson: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: 'FeatureCollection',
    features: carts.map((c: FoodCart) => ({
      type: 'Feature',
      properties: c,
      geometry: {
        type: 'Point',
        coordinates: [c.coordinates.longitude, c.coordinates.latitude],
      },
    })),
  };

  const cartsLayer: LayerProps = {
    id: 'carts',
    type: 'circle',
    paint: {
      'circle-radius': 5,
      'circle-color': '#3E5641',
    },
  };

  useEffect(() => {
    if (selectedFoodCart) {
      mapRef.current?.flyTo({
        center: [
          selectedFoodCart.coordinates.longitude,
          selectedFoodCart.coordinates.latitude,
        ],
        essential: true,
        duration: 500,
      });
      setShowPopup(true);
    }
  }, [selectedFoodCart]);

  // longitude: -122.620961,
  // latitude: 45.539659,

  return (
    <Map
      id="food-carts-map"
      ref={mapRef}
      initialViewState={{
        longitude: -122.657154,
        latitude: 45.541225,
        zoom: 12,
      }}
      interactiveLayerIds={['carts']}
      mapStyle="mapbox://styles/spike-madden/cl75v5ron001t14p71umgfo3o"
      mapboxAccessToken={accessToken}
      onClick={!showPopup ? onClick : undefined}
      cursor={cursor}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <GeolocateControl position="top-right" />
      <Source id="my-data" type="geojson" data={geojson}>
        <Layer {...cartsLayer} />
      </Source>
      {showPopup && selectedFoodCart && (
        <Popup
          longitude={selectedFoodCart.coordinates.longitude}
          latitude={selectedFoodCart.coordinates.latitude}
          anchor="bottom"
          onClose={() => {
            setSelectedFoodCart(null);
            setShowPopup(false);
          }}
          focusAfterOpen={false}
          className="food-cart-popup"
        >
          <VendorPopup cart={selectedFoodCart} />
        </Popup>
      )}
    </Map>
  );
};
