import { FoodCart } from './types/types';

import { ReactComponent as Location } from './icons/location.svg';
import { ReactComponent as Link } from './icons/link.svg';
import { ReactComponent as Food } from './icons/food.svg';

import './vendor.css';
import { getCategories } from './helpers';

export const Vendor = ({ cart }: { cart: FoodCart }) => {
  const categories = getCategories(cart);
  return (
    <>
      <div className="vendor-cart-name">{cart.name}</div>
      <div className="vendor-metadata">
        <Location />
        <span className="vendor-text">{cart.address}</span>
      </div>
      {categories.length > 0 && (
        <div className="vendor-metadata">
          <Food />
          <span className="vendor-text">{categories}</span>
        </div>
      )}

      <div className="vendor-metadata">
        <Link />
        <div className="menu-button">
          <a
            className="food-carts"
            href={cart.url}
            target="_blank"
            rel="noreferrer"
          >
            <span className="vendor-text">what's on the menu?</span>
          </a>
        </div>
      </div>

      {/* <div className="vendor-metadata">
        <Link />
        <a
          className="food-carts-link"
          href={cart.url}
          target="_blank"
          rel="noreferrer"
        >
          <span className="vendor-text">Website</span>
        </a>
      </div> */}
    </>
  );
};
